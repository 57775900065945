<template>
    <div>
      <b-card>
        <div class="d-flex justify-content-between mb-1">
          <h3>{{ $route.name == "add-role" ? "إضافة دور" : "تعديل دور" }}</h3>
            <b-button variant="primary" @click="$router.back()" > <feather-icon icon="ArrowLeftIcon" />  </b-button>
          </div>
        <hr />
        <validation-observer ref="roleUserManage">
          <b-row>
            <b-col cols="4">
              <form-input
                rules="required"
                label="اسم الدور"
                v-model="objectSubmit.role_name"
              />
            </b-col>
          </b-row>
          <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="LockIcon" size="18" />
                <span class="align-middle ml-50">الصلاحيات</span>
              </b-card-title>
            </b-card-header>
            <data-table
              ref="estatesTable"
              :fields="fields"
              ep="/select/permissions"
              :pagination="false"
              striped
            >
              <template #cell(permission_name)="data">
                <div class="d-flex">
                  <b-form-checkbox
                    @change="addSlug(data.item)"
                    :checked="permissions.includes(data.item.id)"
                  >
                  </b-form-checkbox>
                  <span>
                    {{ data.item.name }}
                  </span>
                </div>
              </template>
              <template #cell()="data">
                <b-form-checkbox
                  :checked="itemSearsh(data.field.key, data.item.id)"
                  :disabled="!permissions.includes(data.item.id)"
                  @change="addPermissions(data)"
                />
              </template>
            </data-table>
          </b-card>
          <save-btn
            variant="primary"
            type="submit"
            :loading="Createloading"
            @click="formSubmit()"
          >
          </save-btn>
        </validation-observer>
      </b-card>
    </div>
  </template>
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BCardHeader,
    BCardTitle,
    BButton
  } from "bootstrap-vue";
  import DataTable from "@/components/data-table/index";
  import FormInput from "@/components/form-input/index.vue";
  import saveBtn from "@/components/loader-btn";
  import { mapActions, mapGetters } from "vuex";
  import { ValidationObserver } from "vee-validate";
  
  export default {
    components: {
      BButton,
      BCard,
      DataTable,
      BRow,
      BCol,
      FormInput,
      BFormCheckbox,
      BCardHeader,
      BCardTitle,
      saveBtn,
      ValidationObserver,
    },
    props: ["id"],
    data: () => {
      return {
        selected: false,
        objectSubmit: {
          role_name: "",
          permissions: [],
        },
        permissions: [],
        fields: [
          {
            key: "permission_name",
            label: "الأدوار",
          },
          {
            key: "S",
            label: "إضهار",
          },
          {
            key: "C",
            label: "إضافة",
          },
          {
            key: "D",
            label: "حذف",
          },
          {
            key: "U",
            label: "تعديل",
          },
          {
            key: "O",
            label: "الصلاحيات أخرى ",
          },
        ],
      };
    },
    methods: {
      ...mapActions("settings/manageRols", ["createRole", "updateRole"]),
      itemSearsh(key, id) {
        var idCheck = false;
        if (this.permissions.includes(id)) {
          this.objectSubmit.permissions.forEach((element) => {
            if (id == element.id) {
              if (element.sub_permissions.includes(key)) return (idCheck = true);
            }
          });
        }

        return idCheck;
      },

      formSubmit() {
        this.$refs.roleUserManage.validate().then(async (success) => {
          if (success && this.$route.name == "add-role") {
            this.createRole(this.objectSubmit);
          } else {
            this.updateRole({ id: this.id, data: this.objectSubmit });
          }
        });
      },
  
      addSlug(item) {
        if (!this.permissions.includes(item.id)) {
          this.objectSubmit.permissions.push({
            id: item.id,
            sub_permissions: [],
          });
          this.permissions.push(item.id);
        } else {
          this.objectSubmit.permissions = this.objectSubmit.permissions.filter(
            (data) => data.id != item.id
          );
          this.permissions = this.permissions.filter((data) => data != item.id);
        }
      },
  
      addPermissions(item) {
        this.objectSubmit.permissions.map((data) => {
          if (data.id == item.item.id) {
            if (!data.sub_permissions.includes(item.field.key)) {
              data.sub_permissions.push(item.field.key);
            } else {
              data.sub_permissions = data.sub_permissions.filter(
                (data) => data != item.field.key
              );
            }
          }
        });
      },
    },
    computed: {
      ...mapGetters("settings/manageRols", [
      "Createloading",
      "parmassins",
      "loadingParmassins",
    ]),
    },
    
    watch: {
      selected(val) {},
    },
    created() {
      if (this.id) {
        this.$store
          .dispatch("settings/manageRols/getParmassins", { id: this.id })
          .then(() => {
            this.objectSubmit.role_name = this.parmassins.name;
            this.parmassins.permissions.map((item) => {
              this.objectSubmit.permissions.push({
                id: item.permission_id,
                sub_permissions: item.sub_permission,
              });
              this.permissions.push(item.permission_id);
            });
          });
      }
    },
  };
  </script>